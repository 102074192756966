<template>
  <div class="container radius3">
    <div class="header">
      <slot name="header">
        <span class="headTitle">
          {{ title }}
        </span>
      </slot>
      <div>
        <slot name="right"></slot>
      </div>
    </div>
    <div class="body">
      <MyCard/>
    </div>
  </div>
</template>

<script>

import MyCard from "../myCard";
import {defineComponent} from "vue";
export default defineComponent({
  components: {MyCard},
  props: {
    title: {
      type: String,
      default: '标题'
    }
  },
  data() {
    return {}
  },
  methods: {}
})
</script>

<style lang="scss" scoped>
.body {
  padding: 20px;
}
.container {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;
}

.header {
  width: 100%;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 17px;
  .headTitle {
    display: inline-block;
    line-height: 40px;
    height: 40px;
    position: relative;
  }

  .headTitle::before {
    transition: 0.4s all;
    position: absolute;
    right: 0;
    left: 0;
    bottom: -1px;
    display: block;
    height: 2px;
    background-color: #000;
    content: '';
  }

  .headTitle:hover:before {
    transition: 0.4s all;
    right: -20px;
    left: 0;
  }
}
</style>
