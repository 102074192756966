<template>
  <div class="rightBox">
    <span>原创模板</span>
    <span>古典</span>
    <span>清新</span>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.rightBox {
  > * {
    margin-right: 10px;
    padding-right: 10px;
  }
  >:last-child::after {
    content: '';
  }
  >::after {
    position: relative;
    right: -10px;
    color: #ccc;
    content: '/';
  }
}
</style>
