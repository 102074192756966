<template>
  <div class="home">
    <div class="leftBody">
      <div class="bodyHeader">
        <div class="swiperBox radius3">
          <img style="width: 100%;height: 100%" src="@/assets/bgImage/builds.png"/>
        </div>
        <div class="vertical">
          <div >
            <img class="radius3" style="width: 100%;height: 100%" src="@/assets/bgImage/tree.png"/>
          </div>
          <div>
            <img class="radius3" style="width: 100%;height: 100%" src="@/assets/bgImage/builds.png"/>
          </div>
        </div>
      </div>
      <myContainer title="精彩专题">
        <template v-slot:right>
          <MyBreadcrumb/>
        </template>
      </myContainer>
    </div>
    <div class="rightBody">
      <div class="rightHead radius3">
        <h2 style="margin-top: 0;color: #89919a">我的名片</h2>
        <p style="margin-left: 10px">网名：XXX</p>
        <p style="margin-left: 10px">职业：XXX</p>
        <p style="margin-left: 10px">现居：XXX</p>
        <p style="margin-left: 10px">Email：XXX</p>
        <div style="margin-top: 20px">
          <h3>一个渴望改变自己的迷茫者</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myContainer from "../components/myContainer";
import MyBreadcrumb from "../components/MyBreadcrumb";
export default {
  components: {myContainer,MyBreadcrumb},
  mounted() {
    this.goto()
    // Symbol相关学习
    this.SymbolAbout()
  },
  methods: {
    SymbolAbout() {
      let mySymbol = Symbol();
      let mySymbol2 = Symbol();
      let obj = {};
      obj[mySymbol] = 'Hello!';
      obj[mySymbol2] = '不Hello!';
      // Symbol当作为属性值时不会出现在for...in中
      for(let i in obj) {
        console.log('log----',obj,i,obj[i])
      }
      let arr = Object.getOwnPropertySymbols(obj)
      for(let i in arr) {
        console.log('getOwnPropertySymbols---',obj,obj[arr[i]])
      }
      console.log(obj,obj[mySymbol],Object.getOwnPropertySymbols(obj))
    },
    goto() {
      let [a,b] = [1,2]
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  margin-top: 20px;
  //display: flex;
  //flex-wrap: wrap;
  //justify-content: space-between;
}
.leftBody{
  width: 75%;
  float: left;
}
.rightBody {
  width: 23%;
  float: right;
}
.rightHead {
  box-sizing: border-box;
  padding: 10px;
  height: 260px;
  font-size: 13px;
  color: white;
  width: 100%;
  background:  no-repeat top right rgba(42,42,42,1);
}
.leftBody>:nth-child(1) {
  height: 260px;
}

.bodyHeader {
  display: flex;
  justify-content: space-between;
  .swiperBox {
    width: 70%;
    height: 100%;
    overflow: hidden;
  }
  .vertical{
    width: 28%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    div {
      width: 100%;
      background-color: blue;
      height: 48%;
    }
  }
}
//
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
</style>
